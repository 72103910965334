import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";

var _components;

import "core-js/modules/es.function.name.js";
import { defineComponent, inject, ref } from 'vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'CouponPopup',
  components: (_components = {}, _defineProperty(_components, _Dialog.name, _Dialog), _defineProperty(_components, "SvgIcon", SvgIcon), _components),
  props: {
    open: {
      type: Boolean
    },
    couponText: {
      type: String
    },
    onClose: {
      type: Function
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var t = inject('t');
    var store = useStore();
    var isConfirmPopup = ref(false);
    var hasError = ref(false);

    var checkPoints = function checkPoints() {
      isConfirmPopup.value = false;
    };

    return {
      t: t,
      checkPoints: checkPoints
    };
  }
});