import { defineComponent, inject, ref } from 'vue';
import MemberCenterLayout from '@/components/personal/MemberCenterLayout.vue';
import { useStore } from 'vuex';
import { numberWithCommas } from '@/utils/numberWithCommas';
import CouponPopup from '@/components/personal/CouponPopup.vue';
export default defineComponent({
  name: 'Coupon',
  components: {
    MemberCenterLayout: MemberCenterLayout,
    CouponPopup: CouponPopup
  },
  setup: function setup() {
    var t = inject('t');
    var isMobile = inject('isMobile');
    var store = useStore();
    var hasInput = ref(false);
    var couponInput = ref('');
    var isCouponModalOpen = ref(false);

    var openCouponModal = function openCouponModal() {
      isCouponModalOpen.value = true;
    };

    var closeCouponModal = function closeCouponModal() {
      isCouponModalOpen.value = false;
    };

    return {
      t: t,
      isMobile: isMobile,
      hasInput: hasInput,
      couponInput: couponInput,
      numberWithCommas: numberWithCommas,
      closeCouponModal: closeCouponModal,
      isCouponModalOpen: isCouponModalOpen
    };
  }
});