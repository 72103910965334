import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "dialog-content"
};
var _hoisted_2 = {
  class: "text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_van_dialog = _resolveComponent("van-dialog");

  return _openBlock(), _createBlock(_component_van_dialog, {
    show: _ctx.open,
    className: "coupon-popup",
    width: _ctx.isMobile ? 'calc(100% - 32px)' : 400,
    title: "領取成功",
    showConfirmButton: false
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_svg_icon, {
        class: "icon",
        iconName: "icon-80-check"
      }), _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.couponText), 1), _createElementVNode("button", {
        class: "btn",
        onClick: _cache[0] || (_cache[0] = function () {
          return _ctx.checkPoints();
        })
      }, "查看我的點數")])];
    }),
    _: 1
  }, 8, ["show", "width"]);
}