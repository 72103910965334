import "core-js/modules/es.function.name.js";
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-9e3fe878"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "name"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_level = _resolveComponent("level");

  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["photo-div", "level".concat(_ctx.levelInfo.level_id)])
  }, [_createElementVNode("div", {
    class: "photo",
    style: _normalizeStyle(_ctx.userInfo.avatar ? {
      'background-image': 'url(' + _ctx.userInfo.avatar + ')'
    } : {})
  }, null, 4), _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.userInfo.name), 1), _createVNode(_component_level)], 2);
}