import { computed, defineComponent } from 'vue';
import Level from '@/components/personal/Level.vue';
import { useAuthStore } from '@/hook/useAuthStore';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'Avatar',
  components: {
    Level: Level
  },
  setup: function setup() {
    var store = useStore();
    var userInfo = useAuthStore().userInfo;
    var levelInfo = computed(function () {
      return store.state.user.levelInfo;
    });
    return {
      levelInfo: levelInfo,
      userInfo: userInfo
    };
  }
});