import _toConsumableArray from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import { computed, defineComponent, ref } from 'vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
import Avatar from '@/components/personal/Avatar.vue';
import { useRouter } from 'vue-router';
export default defineComponent({
  name: 'LeftFrame',
  components: {
    SvgIcon: SvgIcon,
    Avatar: Avatar
  },
  setup: function setup() {
    var router = useRouter();
    var currentPath = computed(function () {
      return router.currentRoute.value.path;
    });
    var shrinkId = ref([]);
    var paths = ref([{
      key: 'order',
      icon: 'icon-32-wallet',
      path: '/personal/wallet',
      label: '我的錢包'
    }, {
      key: 'order',
      icon: 'icon-32-order',
      path: '/personal/order',
      label: '訂單管理'
    }, {
      key: 'account',
      icon: 'icon-32-account',
      label: '帳戶管理',
      subPaths: [{
        key: 'info',
        path: '/personal/info',
        label: '個人資料'
      }, // {
      //   key: 'login_method',
      //   path: '/personal/login_method',
      //   label: '登入方式'
      // },
      {
        key: 'security',
        path: '/personal/security',
        label: '帳戶安全'
      }]
    }, {
      key: 'order',
      icon: 'icon-32-level',
      path: '/personal/level',
      label: '會員等級'
    } // {
    //   key: 'order',
    //   icon: 'icon-32-discount',
    //   path: '/personal/coupon',
    //   label: '活動代碼'
    // }
    ]);

    var redirectTo = function redirectTo(path) {
      if (path) {
        router.push(path);
      }
    };

    var toggleShrink = function toggleShrink(key) {
      if (key) {
        if (shrinkId.value.includes(key)) {
          shrinkId.value = shrinkId.value.filter(function (id) {
            return id !== key;
          });
        } else {
          shrinkId.value = [].concat(_toConsumableArray(shrinkId.value), [key]);
        }
      }
    };

    return {
      paths: paths,
      currentPath: currentPath,
      redirectTo: redirectTo,
      toggleShrink: toggleShrink,
      shrinkId: shrinkId
    };
  }
});